import { ContactType } from "components/shareData/shareDialog";
import { RoleTypes, UserOrg, UserProfile, OrgToOrgSubscription } from "types/auth";
import { EstateProps, MapProps, ProjectProps } from "types/estatesAndMap";

const ROLE_HIRARCHY = ["viewer", "commentator", "editor", "admin"];

export interface GetDataOptions {
  user?: UserProfile | null;
  userOrg?: UserOrg | null;
  estates: { [key: string]: EstateProps };
  maps?: { [key: string]: MapProps };
  teamMaps?: { [key: string]: { [key: string]: MapProps } };
  projects?: { [key: string]: ProjectProps };
  teamProjects?: { [key: string]: { [key: string]: ProjectProps } };
  orgEstates?: { [key: string]: EstateProps };
  orgMaps?: { [key: string]: MapProps };
  orgProjects?: { [key: string]: ProjectProps };
  orgToOrgConsumerSubscriptions?: OrgToOrgSubscription[] | null;
  selectedEstateId?: string | null;
  selectedPerson?: ContactType | null;
  overviewText?: string;
  withArchivedData?: boolean;
  withOrgAndEstateEmailMatch?: boolean;
  withOrgToOrgConsumerSubscriptionMatch?: boolean;
  withUserEstateRole?: boolean;
  allRoleOptions: { id: RoleTypes; status: string; label: string }[];
  mapKeysNotAllowed?: string[];
  estateKeysNotAllowed?: string[];
}
const getDataOptions = ({
  user,
  userOrg,
  estates,
  orgEstates,
  maps,
  teamMaps,
  orgMaps,
  projects,
  teamProjects,
  orgProjects,
  orgToOrgConsumerSubscriptions,
  selectedPerson,
  selectedEstateId,
  withArchivedData,
  withOrgAndEstateEmailMatch,
  withOrgToOrgConsumerSubscriptionMatch,
  withUserEstateRole,
  overviewText,
  allRoleOptions,
  mapKeysNotAllowed,
  estateKeysNotAllowed,
}: GetDataOptions) => {
  // Check if user is logged in and if userOrg is available, etc.
  if (!user) return { uniqueEstates: [], list: [] };
  if (withOrgAndEstateEmailMatch && (!userOrg || !estates)) return { uniqueEstates: [], list: [] };
  // Form ignore list
  let mapsIgnore: string[] = [];
  let projectsIgnore: string[] = [];
  if (selectedPerson) {
    if (selectedPerson.ignoreMaps) mapsIgnore = selectedPerson.ignoreMaps.map((el: any) => el.id);
    if (selectedPerson.ignoreProjects)
      projectsIgnore = selectedPerson.ignoreProjects.map((el: any) => el.id);
  }
  let list: any[] = [];
  let userEstateRole: { [key: string]: RoleTypes } = {};
  let tempMapList: any[] = [];
  let tempProjList: any[] = [];
  // Create org maps list
  if (orgMaps && Object.keys(orgMaps).length > 0) {
    Object.values(orgMaps).forEach((el, idx) => {
      if (!mapsIgnore.includes(el.id)) {
        mapsIgnore.push(el.id);
        // Check for org admin
        let roleOptions = [];
        if (user.orgRole === "admin") {
          roleOptions = allRoleOptions;
        } else {
          roleOptions = getRoleOptions({ allRoleOptions, el, userId: user.id });
        }
        const estateId = el.estateId;
        const estateName = el.estateName;
        if (selectedEstateId && selectedEstateId !== estateId) return;
        tempMapList.push({
          estateId: estateId,
          mapId: el.id,
          estateName: estateName,
          mapName: el.name,
          tagField: el.name,
          dataElType: "map",
          label: estateName + ":" + el.name + ":map-" + idx,
          roleOptions: roleOptions,
          role: "admin",
        });
      }
    });
  }
  // Create user maps list
  if (maps && Object.keys(maps).length > 0) {
    Object.values(maps).forEach((el, idx) => {
      // Check if only maps for estates that match the user's email should be returned
      const estateId = el.estateId;
      const estateName = el.estateName;
      const currentRole = getCurrentDataRole({ userId: user.id, el });
      if (
        !userEstateRole[estateId] ||
        ROLE_HIRARCHY.indexOf(currentRole) < ROLE_HIRARCHY.indexOf(userEstateRole[estateId])
      ) {
        userEstateRole[estateId] = currentRole;
      }
      if (withOrgAndEstateEmailMatch && currentRole !== "admin") {
        const match = checkEstateAndOrgEmailMatch({ userOrg, estates, el });
        if (!match) return;
      }
      if (withOrgToOrgConsumerSubscriptionMatch && currentRole !== "admin") {
        const match = checkOrgToOrgConsumerSubscriptionMatch({
          orgToOrgConsumerSubscriptions,
          el,
        });
        if (!match) return;
      }
      if (mapKeysNotAllowed && mapKeysNotAllowed.includes(el.id)) return;
      if (estateKeysNotAllowed && estateKeysNotAllowed.includes(estateId)) return;
      // Check for disable of estate
      let disableEstate = selectedPerson ? true : false;

      // Proceed with adding the map to the list
      if (!mapsIgnore.includes(el.id)) {
        mapsIgnore.push(el.id);
        const roleOptions = getRoleOptions({ allRoleOptions, el, userId: user.id });
        // Check if only maps for specific estate should be returned
        if (selectedEstateId && selectedEstateId !== estateId) return;
        // else return maps
        tempMapList.push({
          estateId: estateId,
          mapId: el.id,
          estateName: estateName,
          mapName: el.name,
          tagField: el.name,
          dataElType: "map",
          label: estateName + ":" + el.name + ":map-" + idx,
          roleOptions: roleOptions,
          role: currentRole,
          disableEstate: disableEstate,
        });
      }
    });
  }

  // Insert team maps
  if (teamMaps && Object.keys(teamMaps).length > 0) {
    Object.keys(teamMaps).forEach((teamMemberId) => {
      if (selectedPerson && selectedPerson.id === teamMemberId) return;
      const teamMemberMaps = teamMaps[teamMemberId];
      Object.values(teamMemberMaps).forEach((el, idx) => {
        if (!mapsIgnore.includes(el.id)) {
          mapsIgnore.push(el.id);
          // Get person role based on team member and all role options
          const currentRole = getCurrentDataRole({ userId: teamMemberId, el });
          const roleOptions = getRoleOptions({ allRoleOptions, el, userId: teamMemberId });
          const roleData = getRoleData({ el, userId: teamMemberId });
          const estateId = el.estateId;
          const estateName = el.estateName;
          if (selectedEstateId && selectedEstateId !== estateId) return;
          tempMapList.push({
            estateId: estateId,
            mapId: el.id,
            estateName: estateName,
            mapName: el.name,
            tagField: el.name,
            dataElType: "map",
            label: estateName + ":" + el.name + ":map-" + idx,
            disableEstate: true,
            roleOptions: roleOptions,
            role: currentRole,
            categories: roleData && roleData.categories ? roleData.categories : undefined,
            expireDate: roleData && roleData.expireDate ? roleData.expireDate : undefined,
            inviterId: teamMemberId,
          });
        }
      });
    });
  }

  // Sort the list according to label
  if (tempMapList.length > 0) {
    tempMapList = tempMapList.sort((a, b) => {
      const valueA = typeof a.label === "string" ? a.label : "";
      const valueB = typeof b.label === "string" ? b.label : "";
      return valueA.localeCompare(valueB);
    });
  }

  // Create org projects list
  if (orgProjects && Object.keys(orgProjects).length > 0) {
    Object.values(orgProjects).forEach((el, idx) => {
      // Check if archived projects should be included
      if (!withArchivedData && el.projectStatus === "archived") return;
      if (!projectsIgnore.includes(el.id)) {
        projectsIgnore.push(el.id);
        // Check for org admin
        let roleOptions = [];
        if (user.orgRole === "admin") {
          roleOptions = allRoleOptions;
        } else {
          roleOptions = getRoleOptions({ allRoleOptions, el, userId: user.id });
        }
        let estateId = el.estateId;
        let estateName = el.estateName;
        if (selectedEstateId && selectedEstateId !== estateId) return;
        tempProjList.push({
          estateId: estateId,
          projectId: el.id,
          estateName: estateName,
          projectName: el.name,
          mapName: el.name,
          tagField: el.name,
          dataElType: "project",
          label: estateName + ":" + el.name + ":project-" + idx,
          role: "admin",
          roleOptions: roleOptions,
        });
      }
    });
  }
  // Create projects list
  if (projects && Object.keys(projects).length > 0) {
    Object.values(projects).forEach((el, idx) => {
      // Check if only maps for estates that match the user's email should be returned
      if (withOrgAndEstateEmailMatch) {
        const match = checkEstateAndOrgEmailMatch({ userOrg, estates, el });
        if (!match) return;
      }
      if (withOrgToOrgConsumerSubscriptionMatch) {
        const match = checkOrgToOrgConsumerSubscriptionMatch({ orgToOrgConsumerSubscriptions, el });
        if (!match) return;
      }
      // Check if project should be ignored
      if (estateKeysNotAllowed && estateKeysNotAllowed.includes(el.estateId)) return;
      // Check if archived projects should be included
      if (!withArchivedData && el.projectStatus === "archived") return;
      // Proceed with adding the project to the list
      if (!projectsIgnore.includes(el.id)) {
        projectsIgnore.push(el.id);
        let estateId = el.estateId;
        let estateName = el.estateName;
        const roleOptions = getRoleOptions({ allRoleOptions, el, userId: user.id });
        // Check if only projects for specific estate should be returned
        if (selectedEstateId && selectedEstateId !== estateId) return;
        tempProjList.push({
          estateId: estateId,
          projectId: el.id,
          estateName: estateName,
          projectName: el.name,
          mapName: el.name,
          tagField: el.name,
          dataElType: "project",
          label: estateName + ":" + el.name + ":project-" + idx,
          roleOptions: roleOptions,
        });
      }
    });
  }

  if (teamProjects && Object.keys(teamProjects).length > 0) {
    Object.keys(teamProjects).forEach((teamMemberId) => {
      if (selectedPerson && selectedPerson.id === teamMemberId) return;
      const teamMemberProjects = teamProjects[teamMemberId];
      Object.values(teamMemberProjects).forEach((el, idx) => {
        if (!projectsIgnore.includes(el.id)) {
          projectsIgnore.push(el.id);
          // Get person role based on team member and all role options
          const estateId = el.estateId;
          const estateName = el.estateName;
          const currentRole = getCurrentDataRole({ userId: teamMemberId, el });
          const roleOptions = getRoleOptions({ allRoleOptions, el, userId: teamMemberId });
          const roleData = getRoleData({ el, userId: teamMemberId });
          if (selectedEstateId && selectedEstateId !== estateId) return;
          tempProjList.push({
            estateId: estateId,
            projectId: el.id,
            estateName: estateName,
            projectName: el.name,
            mapName: el.name,
            tagField: el.name,
            dataElType: "project",
            label: estateName + ":" + el.name + ":project-" + idx,
            roleOptions: roleOptions,
            role: currentRole,
            inviterId: teamMemberId,
            categories: roleData && roleData.categories ? roleData.categories : undefined,
            expireDate: roleData && roleData.expireDate ? roleData.expireDate : undefined,
          });
        }
      });
    });
  }

  if (tempProjList.length > 0) {
    tempProjList = tempProjList.sort((a, b) => {
      const valueA = typeof a.label === "string" ? a.label : "";
      const valueB = typeof b.label === "string" ? b.label : "";
      return valueA.localeCompare(valueB);
    });
  }
  // Collect list into one array
  const tempList: any[] = [...tempMapList, ...tempProjList].sort(
    sortAccordingToEstateIdAndDataElType
  );
  // Inject estate overviews options
  let uniqueEstates: string[] = [];
  let estateIdsAndNames: { [key: string]: string } = {};
  tempList.forEach((el) => {
    if (!uniqueEstates.includes(el.estateId) && !el.disableEstate) {
      // Check if estate is external (based on orgId)
      let dataElType = "";
      let disableEstate = false;
      if (orgEstates) {
        const { orgId } = user;
        const estOrgId = orgEstates[el.estateId]?.orgId;
        if (orgId !== estOrgId) dataElType = "extEstate";
      }
      if (withUserEstateRole) {
        disableEstate = disableEstateUserRole({
          userOrg,
          estates,
          estateRole: userEstateRole[el.estateId],
          el,
        });
      }

      if (overviewText && overviewText !== "" && !disableEstate) {
        list.push({
          estateId: el.estateId,
          mapId: null,
          projectId: null,
          estateName: el.estateName,
          mapName: overviewText,
          projectName: overviewText,
          tagField: el.estateName,
          label: el.estateName,
          dataElType: dataElType || "estate",
          roleOptions: el.roleOptions,
          disabled: disableEstate,
        });
      }

      // uniqueEstates.push(el.estateId);
      // estateIdsAndNames[el.estateId] = el.estateName;
    }
    uniqueEstates.push(el.estateId);
    estateIdsAndNames[el.estateId] = el.estateName;
    list.push(el);
  });
  return { list, uniqueEstates, estateIdsAndNames };
};
export default getDataOptions;

// --- Helper functions --- //
interface GetRoleOptions {
  allRoleOptions: { id: RoleTypes; status: string; label: string }[];
  userId?: string;
  el: MapProps | ProjectProps;
}

function getRoleData({ el, userId }: { el: MapProps | ProjectProps; userId: string }) {
  if (!userId || !el.roles || !el.roles[userId]) return null;
  return el.roles[userId];
}

function getRoleOptions({ allRoleOptions, el, userId }: GetRoleOptions) {
  if (!userId || !el.roles || !el.roles[userId]) return [];
  return allRoleOptions.slice(
    0,
    allRoleOptions.findIndex((option) => option.id === el.roles![userId!].role) + 1
  );
}

function getCurrentDataRole({ userId, el }: { userId?: string; el: MapProps | ProjectProps }) {
  if (!userId || !el.roles || !el.roles[userId]) return "viewer";
  return el.roles[userId].role;
}

function checkEstateAndOrgEmailMatch({
  userOrg,
  estates,
  el,
}: {
  userOrg?: UserOrg | null;
  estates?: { [key: string]: EstateProps } | null;
  el: MapProps | ProjectProps;
}) {
  // Input checking
  if (!userOrg || !estates || !el) return false;
  const orgEmail = userOrg?.email;
  const estateEmail = estates[el.estateId]?.email;
  const orgId = userOrg?.id;
  const estateOrgId = estates[el.estateId]?.orgId;
  if (orgId && estateOrgId && orgId === estateOrgId) return true;
  if (orgEmail && estateEmail && orgEmail === estateEmail) return true;

  return false;
}

function checkOrgToOrgConsumerSubscriptionMatch({
  orgToOrgConsumerSubscriptions,
  el,
}: {
  orgToOrgConsumerSubscriptions?: OrgToOrgSubscription[] | null;
  el: MapProps | ProjectProps;
}) {
  if (!orgToOrgConsumerSubscriptions || !el) return false;
  const orgToOrgConsumerSubscription = orgToOrgConsumerSubscriptions.find(
    (sub) => sub.estateId === el.estateId
  );
  if (orgToOrgConsumerSubscription) return true;

  return false;
}

function disableEstateUserRole({
  userOrg,
  estates,
  estateRole,
  el,
}: {
  userOrg?: UserOrg | null;
  estates?: { [key: string]: EstateProps } | null;
  estateRole: RoleTypes;
  el: MapProps | ProjectProps;
}) {
  if (!userOrg || !estates || !el) return true;
  const orgId = userOrg?.id;
  const estateOrgId = estates[el.estateId]?.orgId;
  if (orgId && estateOrgId && orgId === estateOrgId) return false;
  if (estateRole === "admin") return false;

  return true;
}

// Sort the list according to estateId and dataElType
function sortAccordingToEstateIdAndDataElType(a: any, b: any) {
  // Sort by estateId first
  const valueA = typeof a.estateId === "string" ? a.estateId : "";
  const valueB = typeof b.estateId === "string" ? b.estateId : "";
  const estateIdComparison = valueA.localeCompare(valueB);
  // If estateId is the same, sort by dataElType
  if (estateIdComparison === 0) {
    if (a.dataElType === "map" && b.dataElType === "project") {
      return -1; // "map" comes before "project"
    } else if (a.dataElType === "project" && b.dataElType === "map") {
      return 1; // "project" comes after "map"
    }
  }

  return estateIdComparison;
}
