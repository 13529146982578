import React, { SyntheticEvent, useState } from "react";

// material ui
import { Chip, Box, Autocomplete, TextField, Typography, ListItem } from "@mui/material";
import { styled } from "@mui/system";
import UpgradeNotification from "components/display/upgradeNotification";

type AutocompleteProps = {
  title: string;
  label: string;
  disabled?: boolean;
  value?: any[];
  data: any[];
  groupByField?: string;
  groupByFieldToLabel?: { [key: string]: string };
  renderOptionField: string;
  renderTagField: string;
  showTitle: boolean;
  showLabel: boolean;
  noOptionsText?: string;
  condensed?: boolean;
  multiple?: boolean;
  mobile?: boolean;
  noWrap?: boolean;
  upgradeNotification?: {
    variant: "info" | "warning" | "error";
    text: string;
    dismissable: boolean;
  };
  changeHandler: (e: SyntheticEvent<Element, Event>, v: any) => void;
};

interface TypeLegendProps {
  variant: string | undefined;
}

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  backgroundColor: theme.palette.secondary.light,
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const TypeLegend = styled("div")<TypeLegendProps>(({ theme, variant }) => {
  let bgColor;
  let show;
  switch (variant) {
    case "map":
      bgColor = theme.palette.primary.main;
      show = true;
      break;
    case "extMap":
      bgColor = theme.palette.secondary.main;
      show = true;
      break;
    case "estate":
      bgColor = "black";
      show = true;
      break;
    case "extEstate":
      bgColor = theme.palette.secondary.main;
      show = true;
      break;
    case "project":
      bgColor = theme.palette.info.main;
      show = true;
      break;
    default:
      bgColor = theme.palette.primary.lighter;
      show = false;
  }

  if (!show) return null;

  return {
    minWidth: 8,
    maxWidth: 8,
    height: 20,
    backgroundColor: bgColor,
    borderRadius: 2,
    marginRight: 8,
  };
});

// ==============================|| AUTOCOMPLETE - GROUPED ||============================== //

const AutoCompleteGrouped = ({
  title,
  label,
  value,
  data,
  groupByField,
  groupByFieldToLabel,
  renderOptionField,
  renderTagField,
  showTitle,
  showLabel,
  noOptionsText,
  condensed,
  multiple,
  noWrap,
  mobile,
  disabled,
  upgradeNotification,
  changeHandler,
}: AutocompleteProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      {showTitle && (
        <Typography variant="h5" sx={{ mb: 1 }}>
          {title}
        </Typography>
      )}

      <Autocomplete
        multiple={multiple}
        fullWidth
        disabled={disabled}
        id="autocomplete-grouped"
        value={value}
        options={data}
        groupBy={
          groupByField
            ? (option) =>
                groupByFieldToLabel
                  ? groupByFieldToLabel[option[groupByField]] || option[groupByField]
                  : option[groupByField]
            : undefined
        }
        noOptionsText={noOptionsText ? noOptionsText : null}
        getOptionLabel={(option) => option.label}
        getOptionDisabled={(option) => {
          if (option.disabled !== undefined) return option.disabled;
          return false;
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        defaultValue={data}
        onChange={(e, v) => changeHandler(e, v)}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={label}
            label={showLabel ? title : null}
            sx={{ "& .MuiFormLabel-root": { fontSize: 14 } }}
            color="secondary"
          />
        )}
        renderGroup={(params) => {
          const key = params.key as string | number;
          let keyString: string = "";
          if (key && typeof key === "number") {
            keyString = key.toString(); // Convert number to string
          } else if (key && typeof key === "string") {
            keyString = key; // It's already a string
          }
          return (
            <li key={params.key} style={{ marginTop: 0 }}>
              <GroupHeader sx={{ bgcolor: "seconday.darker", zIndex: 1 }}>
                <Typography variant="subtitle2" color="textPrimary" sx={{ fontSize: 16 }}>
                  {params.group}
                </Typography>
              </GroupHeader>
              <GroupItems sx={{ mt: 0, mb: 1.5 }}>{params.children}</GroupItems>
              {upgradeNotification && keyString === "0" && (
                <Box sx={{ p: 1, mt: -1 }}>
                  <UpgradeNotification
                    variant={upgradeNotification.variant}
                    text={upgradeNotification.text}
                    dismissable={upgradeNotification.dismissable}
                  />
                </Box>
              )}
            </li>
          );
        }}
        renderOption={(props, option: any) => (
          <ListItem
            {...props}
            sx={{
              "&&:hover": {
                backgroundColor: "primary.lighter",
              },
            }}
          >
            <TypeLegend variant={option.dataElType} /> {option[renderOptionField]}
          </ListItem>
        )}
        ListboxComponent={React.forwardRef((props, ref) => (
          <Box {...props} ref={ref} />
        ))}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            let chipColor;
            let color;
            switch (option.dataElType) {
              case "map":
                chipColor = "primary.main";
                color = "white";
                break;
              case "extMap":
                chipColor = "secondary.main";
                color = "white";
                break;
              case "estate":
                chipColor = "black";
                color = "white";
                break;
              case "extEstate":
                chipColor = "secondary.main";
                color = "white";
                break;
              case "project":
                chipColor = "info.main";
                color = "white";
                break;
              default:
                chipColor = "primary.lighter";
                color = "primary.dark";
            }

            let top = 3;
            if (mobile) {
              top = 2;
            }
            const shouldRenderChip = index < top || isFocused || isHovered;
            if (shouldRenderChip) {
              return (
                <Chip
                  label={option[renderTagField]}
                  {...getTagProps({ index })}
                  disabled={disabled}
                  sx={{
                    minWidth: "90px",
                    margin: condensed ? "2px" : "4px",
                    bgcolor: chipColor,
                    color: color,
                    borderRadius: 1,
                    height: condensed ? 24 : 28,
                    pl: condensed ? 1.0 : 1.5,
                    pr: condensed ? 1.0 : 1.5,
                    lineHeight: condensed ? "24px" : "28px",
                    whiteSpace: "nowrap", // Prevent wrapping inside the tag
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    "&:focus": {
                      whiteSpace: "normal", // Allow wrapping when the tag is focused
                    },
                    marginBottom: "4px", // Ensures spacing between wrapped chips
                    "&:last-child": {
                      marginBottom: "0px", // Ensures no extra space at the bottom
                    },
                    "& .MuiChip-label": {
                      paddingLeft: condensed ? 0.5 : 0,
                      paddingRight: 0,
                    },
                    "& .MuiSvgIcon-root": {
                      color: color,
                      "&:hover": {
                        color: color,
                      },
                      ml: 1,
                      mr: condensed ? -0.25 : -0.75,
                      height: condensed ? 18 : null,
                    },
                  }}
                />
              );
            } else if (index === top && !isFocused && !isHovered) {
              return (
                <Chip
                  key={index}
                  label={`+${tagValue.length - top}`}
                  sx={{
                    margin: condensed ? "2px" : "4px",
                    bgcolor: chipColor,
                    color: color,
                    borderRadius: 1,
                    height: condensed ? 24 : 28,
                    lineHeight: condensed ? "24px" : "28px",
                  }}
                />
              );
            } else {
              return null;
            }
          })
        }
        sx={{
          // Root style
          "& .MuiOutlinedInput-root": {
            px: 0.5,
            py: 0.4,
            minHeight: condensed ? 36 : 41,
            flexWrap: "nowrap", // Prevent wrapping by default
            overflow: "hidden",
            alignItems: "center", // Vertically center the chips
            "&:hover, &:focus-within": {
              flexWrap: noWrap ? "nowrap" : "wrap", // Allow wrapping when hovered or focused
            },
          },
          "& .MuiInputBase-input": {
            fontSize: 14,
            height: 15,
          },
          "& .MuiFormLabel-root": {
            marginTop: "-5px",
          },
          "& .MuiInputLabel-shrink": {
            marginTop: "0px",
          },
        }}
      />
    </>
  );
};

export default AutoCompleteGrouped;
