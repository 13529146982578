import { createContext, ReactNode, useState } from "react";

// Project import
import { AppFunctionalityProps, AppConfig, AppConfigSubNames } from "types/appFunctionality";
import defaultConfig from "config/appConfigFiles/default.json";
//@ts-ignore
import { mergeWith, isArray, isEmpty, union } from "lodash";

const initialState: AppFunctionalityProps = {
  // @ts-expect-error
  config: { ...defaultConfig },
  subConfigName: "unknown",
};

const AppFunctionalityContext = createContext(initialState);

type AppFuncConfigProviderProps = {
  children: ReactNode;
};

function AppFunctionalityProvider({ children }: AppFuncConfigProviderProps) {
  const [appConfig, setConfig] = useState(initialState);

  const onChangeAppFunctionalityConfig = (
    newConfig: AppConfig,
    subConfigName?: AppConfigSubNames
  ) => {
    // Create a new object for the updated configuration
    const updatedConfig = mergeWith({}, appConfig.config, newConfig, customizer);
    let newConfigObject = { ...appConfig, config: updatedConfig };
    // Check if a subConfigName is provided
    if (subConfigName) {
      // Set the subConfigName
      newConfigObject = { ...newConfigObject, subConfigName };
    }
    // Set the new state with this updated configuration
    setConfig({ ...newConfigObject });
  };

  return (
    <AppFunctionalityContext.Provider
      value={{
        ...appConfig,
        onChangeAppFunctionalityConfig,
      }}
    >
      {children}
    </AppFunctionalityContext.Provider>
  );
}

export { AppFunctionalityContext, AppFunctionalityProvider };

// --- Helper functions --- //
const customizer = (objValue: any, srcValue: any) => {
  // If srcValue is an empty array, replace objValue with srcValue
  if (isArray(srcValue) && isEmpty(srcValue)) {
    return srcValue;
  }
  // If both values are arrays, merge them
  if (isArray(objValue) && isArray(srcValue)) {
    return union(objValue, srcValue);
  }
  // Otherwise, let lodash merge handle the merge
};
